import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChakraProvider } from "@chakra-ui/react";

import "./App.css";
import {
  LanguageProvider,
  ThemeProvider,
  useTheme,
} from "./components/index/ThemeContext"; // Assuming this is your theme context
import { motion, AnimatePresence } from "framer-motion";

// Import your components
import Navbar from "./components/index/Navbar";
import Cover from "./components/index/Cover";
import AboutUs from "./components/index/AboutUs";
import ComingEvents from "./components/index/ComingEvents";
import PastEvents from "./components/index/PastEvents";
import BlogSection from "./components/index/BlogSection";
import BecomeMember from "./components/index/BecomeMember";
import Contact from "./components/index/Contact";
import Wechat from "./components/wechat/wechat";
import AdminAccess from "./components/adminAccess/adminAccess";
import EntryPage from "./components/index/EntryPage";

// Reusable component to trigger theme change
const ThemedPage = ({ changeTheme }) => {
  const [rerenderKey, setRerenderKey] = useState(0); // State to track re-renders

  useEffect(() => {
    changeTheme(); // Trigger the theme change when the component mounts
    setRerenderKey((prevKey) => prevKey + 1);
  }, [changeTheme]);

  return (
    <motion.div
      initial={{ opacity: 0.1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.8 }}
    >
      <Navbar />
      <Cover />
      <AboutUs />
      <ComingEvents />
      <PastEvents key={`past-events-${rerenderKey}`} />
      <BlogSection key={`blog-section-${rerenderKey}`} />
      <BecomeMember />
      <Contact />
    </motion.div>
  );
};


// Pro Page
const ProPage = () => {
  const { changeToPro } = useTheme();
  return <ThemedPage changeTheme={changeToPro} />;
};

// UNSW Page
const UNSWPage = () => {
  const { changeToUNSW } = useTheme();
  return <ThemedPage changeTheme={changeToUNSW} />;
};

// UMelb Page
const UMelbPage = () => {
  const { changeToUMelb } = useTheme();
  return <ThemedPage changeTheme={changeToUMelb} />;
};

function AnimatedRoutes() {
  const location = useLocation(); // React Router hook to track current location

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<EntryPage />} />
        {/* Theme-specific routes */}
        <Route path="/pro" element={<ProPage />} />
        <Route path="/unsw" element={<UNSWPage />} />
        <Route path="/umelb" element={<UMelbPage />} />
        <Route path="/wechat" element={<Wechat />} />
        <Route path="/exec" element={<AdminAccess />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <ChakraProvider resetCSS={false}>
      <Router>
        <div className="App">
          <ThemeProvider>
            <LanguageProvider>
              <header></header>
              <main>
                <AnimatedRoutes />
              </main>
              <footer></footer>
            </LanguageProvider>
          </ThemeProvider>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;
